<template>
  <div>
    <h1>Team Overview</h1>
    <div class="row mb-3">
      <div class="col-md-2">
        <b-form-select v-model="selectedOffice" @change="filterData">
          <option value="">All Teams</option>
          <option v-for="office in uniqueOffices" :key="office" :value="office">{{ office }}</option>
        </b-form-select>
      </div>
      <div class="col-md-2">
        <b-form-select v-model="selectedYear" @change="filterData">
          <option v-for="year in uniqueYears" :key="year" :value="year">{{ year }}</option>
        </b-form-select>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-bordered table-striped table-hover">
        <thead>
          <tr>
            <th>Month</th>
            <th colspan="3" class="team-sum">Team Sum</th>
            <th v-for="user in processedUsers" :key="user" colspan="5">{{ user }}</th>
          </tr>
          <tr>
            <th></th>
            <th>O.T</th>
            <th>V</th>
            <th>S.L</th>
            <template v-for="user in processedUsers">
              <th v-for="field in ['R.H', 'W.H', 'O.T', 'V', 'S.L']" :key="`${user}_${field}`">{{ field }}</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="month in filteredMonths" :key="month">
            <td>{{ month }}</td>
            <td>{{ getTeamSumForField(month, 'totalOvertimeHours').toFixed(2) }}</td>
            <td>{{ getTeamSumForField(month, 'holidaysCount').toFixed(0) }}</td>
            <td>{{ getTeamSumForField(month, 'sickLeavesCount').toFixed(0) }}</td>
            <template v-for="user in displayedUsers">
              <td
                v-for="field in ['totalRequiredHours', 'totalWorkingHours', 'totalOvertimeHours', 'holidaysCount', 'sickLeavesCount']"
                :key="`${user}_${field}`">
                {{ getUserData(month, user, field).toFixed(['totalRequiredHours', 'totalWorkingHours',
                  'totalOvertimeHours'].includes(field) ? 2 : 0) }}
              </td>
            </template>
          </tr>
          <tr class="total-row">
            <td>Total</td>
            <td>{{ getTotalForField('totalOvertimeHours').toFixed(2) }}</td>
            <td>{{ getTotalForField('holidaysCount').toFixed(0) }}</td>
            <td>{{ getTotalForField('sickLeavesCount').toFixed(0) }}</td>
            <template v-for="user in displayedUsers">
              <td
                v-for="field in ['totalRequiredHours', 'totalWorkingHours', 'totalOvertimeHours', 'holidaysCount', 'sickLeavesCount']"
                :key="`${user}_${field}`">
                {{
                  ['totalRequiredHours', 'totalWorkingHours', 'totalOvertimeHours'].includes(field)
                    ? getUserTotalForField(user, field).toFixed(2)
                    : getUserTotalForField(user, field)
                }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="legends">
      <span class="legend-item">R.H</span>: Required Hours,
      <span class="legend-item">W.H</span>: Working Hours,
      <span class="legend-item">O.T</span>: Overtime,
      <span class="legend-item">V</span>: Vacation,
      <span class="legend-item">S.L</span>: Sick Leave
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const currentYear = new Date().getFullYear();
    return {
      overviews: [],
      selectedOffice: '',
      selectedYear: currentYear,
      uniqueUsers: [],
    };
  },
  computed: {
    uniqueOffices() {
      return [...new Set(this.overviews.map(overview => overview.attributes.users_permissions_user.data.attributes.office))];
    },
    uniqueYears() {
      return [...new Set(this.overviews.map(overview => overview.attributes.year))];
    },
    uniqueUsers() {
      return [...new Set(this.overviews.map(overview => overview.attributes.users_permissions_user.data.attributes.username))];
    },
    uniqueMonths() {
      return [...new Set(this.overviews.map(overview => overview.attributes.month))];
    },
    filteredMonths() {
      return this.uniqueMonths
        .filter(month =>
          this.overviews.some(overview =>
            (!this.selectedOffice || overview.attributes.users_permissions_user.data.attributes.office === this.selectedOffice) &&
            (!this.selectedYear || overview.attributes.year === this.selectedYear) &&
            overview.attributes.month === month
          )
        )
        .sort((a, b) => {
          const monthsOrder = {
            'January': 1,
            'February': 2,
            'March': 3,
            'April': 4,
            'May': 5,
            'June': 6,
            'July': 7,
            'August': 8,
            'September': 9,
            'October': 10,
            'November': 11,
            'December': 12
          };
          return monthsOrder[a] - monthsOrder[b];
        });
    },
    displayedUsers() {
      return this.uniqueUsers.filter(user =>
        this.overviews.some(overview =>
        overview.attributes.users_permissions_user.data.attributes.status !== 'archived' && // Exclude archived users
          (!this.selectedOffice || overview.attributes.users_permissions_user.data.attributes.office === this.selectedOffice) &&
          (!this.selectedYear || overview.attributes.year === this.selectedYear) &&
          overview.attributes.users_permissions_user.data.attributes.username === user
        )
      );
    },

    processedUsers() {
      return this.uniqueUsers
        .filter(user =>
          this.overviews.some(overview =>
          overview.attributes.users_permissions_user.data.attributes.status !== 'archived'&&
            (!this.selectedOffice || overview.attributes.users_permissions_user.data.attributes.office === this.selectedOffice) &&
            (!this.selectedYear || overview.attributes.year === this.selectedYear) &&
            overview.attributes.users_permissions_user.data.attributes.username === user
          )
        )
        .map(user =>
          user.split('@')[0].replace(/\./g, ' ').replace(/\b\w/g, match => match.toUpperCase())
        );
    },
    teamSumByMonth() {
      const teamSum = {};
      for (const month of this.filteredMonths) {
        const sum = {
          totalOvertimeHours: 0,
          holidaysCount: 0,
          sickLeavesCount: 0
        };
        for (const overview of this.overviews) {
          if (
            overview.attributes.users_permissions_user.data.attributes.status !== 'archived' && // Exclude archived users
            (!this.selectedOffice || overview.attributes.users_permissions_user.data.attributes.office === this.selectedOffice) &&
            (!this.selectedYear || overview.attributes.year === this.selectedYear) &&
            overview.attributes.month === month
          ) {
            sum.totalOvertimeHours += parseFloat(overview.attributes.totalOvertimeHours) || 0;
            sum.holidaysCount += parseFloat(overview.attributes.holidaysCount) || 0;
            sum.sickLeavesCount += parseFloat(overview.attributes.sickLeavesCount) || 0;
          }
        }
        teamSum[month] = sum;
      }
      return teamSum;
    },
  },
  mounted() {
    this.$xhr.get('/api/overviews?populate=*').then(response => {
      this.overviews = response.data.data;
      this.uniqueUsers = [...new Set(this.overviews.map(overview => overview.attributes.users_permissions_user.data.attributes.username))].sort();

    });
  },
  methods: {
    getUserData(month, user, field) {
      const overview = this.overviews.find(overview =>
        overview.attributes.month === month &&
        overview.attributes.year === this.selectedYear && // Add this condition
        overview.attributes.users_permissions_user.data.attributes.username === user
      );
      if (overview && !isNaN(parseFloat(overview.attributes[field]))) {
        return parseFloat(overview.attributes[field]);
      } else {
        return 0;
      }
    },

    filterData() {
      const selectedOffice = this.selectedOffice;
      const selectedYear = this.selectedYear;
      const filteredData = this.overviews.filter(overview =>
        (!this.selectedOffice || overview.attributes.users_permissions_user.data.attributes.office === this.selectedOffice) &&
        (!this.selectedYear || overview.attributes.year === this.selectedYear)
      );
      const offices = [...new Set(filteredData.map(overview => overview.attributes.users_permissions_user.data.attributes.office))];
      const years = [...new Set(filteredData.map(overview => overview.attributes.year))];
      this.uniqueOffices = offices;
      this.uniqueYears = years;
      this.selectedOffice = offices.includes(selectedOffice) ? selectedOffice : '';
      this.selectedYear = years.includes(selectedYear) ? selectedYear : '';
    },
    getUserTotalForField(user, field) {
    return this.filteredMonths.reduce((sum, month) => {
      const userData = this.overviews.find(overview =>
        overview.attributes.month === month &&
        overview.attributes.year === this.selectedYear &&
        overview.attributes.users_permissions_user.data.attributes.username === user
      );
      return sum + (userData ? parseFloat(userData.attributes[field]) || 0 : 0);
    }, 0);
  },
    getTotalForField(field) {
      return this.filteredMonths.reduce((sum, month) => {
        return sum + this.teamSumByMonth[month][field];
      }, 0);
    },
    getTeamSumForField(month, field) {
      return this.teamSumByMonth[month][field];
    },
  },
};
</script>

<style scoped>
.legends {
  margin-top: 50px;
}

.legend-item {
  font-weight: bold;
  margin-right: 5px;
}

.total-row {
  font-weight: bold;
}

.table-hover tbody tr:hover {
  background-color: #fff3cd;
}
</style>
