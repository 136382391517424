<template>
  <div>
    <input v-model="search" type="text" placeholder="Search" class="adminsearch" />
    <div class="office-filter">
      <label for="office-filter">Select Team:</label>
      <b-form-select class="off" v-model="selectedOffice" :options="officeOptions"></b-form-select>
      <span v-if="selectedOffice" class="clear-filter" @click="clearOfficeFilter">&#10005;</span>
    </div>
    <b-table sticky-header class="admintable" striped hover :items="filteredItems" :fields="fields" :sort-by="'username'">
      <template v-slot:cell(actions)="{ item }">
        <span>
  <b-button size="sm" variant="info" :to="{ name: 'General', params: { id: item.id }}">Setting</b-button>
  <b-button size="sm" variant="dark" :to="{ name: 'Months', params: { id: item.id }}">Time Sheet</b-button>
  <b-button 
    size="sm"
    :variant="item.status === 'archived' ? 'warning' : 'success'"
    @click="toggleArchiveStatus(item)">
    {{ item.status === 'archived' ? 'Unarchive' : 'Archive' }}
  </b-button>
</span>

      </template>
      <template v-slot:cell(office)="{ item }">
        <b-form-select v-model="item.office" :options="officeOptions" @change="updateOffice(item)"></b-form-select>
      </template>
      <template v-slot:cell(username)="{ item }">
        {{ formatName(item.username) }}
      </template>
    </b-table>
  </div>
</template>

<script>

export default {
  name: "AdminOverview",

  data() {
    return {
      list: [],
      search: '',
      selectedOffice: '',
      officeOptions: ['Team Ocean Cruise', 'Team River Cruise', 'Team Merck', 'Team Hagebau','Administration']
    }
  },
  computed: {
    filteredItems() {
      let items = this.list;

      if (this.search) {
        const searchTerm = this.search.toLowerCase();
        items = items.filter(item => {
          return (
            item.username.toLowerCase().includes(searchTerm) ||
            item.email.toLowerCase().includes(searchTerm)
          );
        });
      }

      if (this.selectedOffice) {
        items = items.filter(item => item.office === this.selectedOffice);
      }

      return items;
    },
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'username', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'office', label: 'Team' },
        { key: 'status', label: 'Status' },
        { key: 'actions', label: 'Actions' }
      ];
    }
  },
  methods: {
    async toggleArchiveStatus(user) {
    try {
      const newStatus = user.status === "archived" ? "active" : "archived";

      await this.$xhr.put(`/api/users/${user.id}`, { status: newStatus });

      // Update UI instantly
      user.status = newStatus;

      this.$bvToast.toast(
        `User has been ${newStatus === "archived" ? "archived" : "unarchived"} successfully!`, 
        { title: "Success", variant: "success", solid: true }
      );
    } catch (error) {
      console.error("Error updating status:", error);
      this.$bvToast.toast("Failed to update user status.", { title: "Error", variant: "danger", solid: true });
    }
  },
    getData() {
      this.$xhr.get('/api/users').then((result) => {
        this.list = result.data;
      });
    },
    async updateOffice(item) {
      try {
        const { id, office } = item;
        const data = { office };

        const existingEntry = this.list.find(entry => entry.id === id);

        if (existingEntry) {
          await this.$xhr.put(`/api/users/${id}`, data);
          existingEntry.office = office;
        } else {
          await this.$xhr.post('/api/users', data);
          this.list.push(item);
        }

        this.makeToast(true); // Show toast notification after office is updated
      } catch (error) {
        console.error(error);
      }
    },

    makeToast(append = false) {
      this.$bvToast.toast("Team assigned successfully!", {
        title: "Success",
        variant: "success",
        solid:true,
      });
    },
    clearOfficeFilter() {
      this.selectedOffice = '';
    },
    formatName(username) {
      const parts = username.split('@')[0].split('.')
      const formattedName = parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')
      return `${formattedName}`
    },
    
  },
  mounted() {
    this.getData();
  }
}
</script>
<style scoped>
.imp{
  margin-top: 180px;
}
</style>